<template>
  <div>
    <BaseModal ref="modal" title="Manuele facturatie" max-width="tw-max-w-4xl">
      <FormulateForm
        v-show="!preview"
        #default="{ isLoading }"
        v-model="values"
        name="manualInvoicing"
        @submit="showPreview"
      >
        <div class="tw-grid tw-grid-cols-2 tw-gap-x-4">
          <FormulateInput
            name="document_type"
            label="Documenttype"
            type="select"
            validation="required"
            :options="[
                { label: 'Factuur', value: 1 },
                { label: 'Creditnota', value: 2 }
            ]"
            outer-class="tw-my-2"
          />
          <FormulateInput
            name="document_date"
            label="Documentdatum"
            type="date"
            placeholder="YYYY-MM-DD"
            :value="today.date"
            validation="bail|required|date:YYYY-MM-DD"
            outer-class="tw-my-2"
          />
        </div>

        <div class="tw-grid tw-grid-cols-2 tw-gap-x-4">
          <FormulateInput
            name="company"
            label="Facturerende vennootschap"
            type="select"
            placeholder="Selecteer een vennootschap"
            validation="required"
            :options="companyOptions"
            outer-class="tw-my-2"
            @change="handleCompanyChange"
          />
          <FormulateInput
            name="journal_id"
            label="Dagboek"
            type="select"
            placeholder="Selecteer een dagboek"
            validation="required"
            :options="journals"
            :disabled="!values.company"
            outer-class="tw-my-2"
          />
        </div>

        <FormulateInput
          type="autocomplete"
          auto-complete-type="invoiceContacts"
          :params="{ is_archived: false }"
          name="invoice_recipient_grouping"
          label="Facturatiecontact"
          validation="required"
          placeholder="Zoek op facturatiecontact"
          outer-class="tw-mt-2 tw-my-4"
          data-lpignore="true"
        />

        <hr class="tw-my-4" />
        <FormulateInput
          type="group"
          name="lines"
          :repeatable="true"
          add-label="+ Nieuwe lijn"
          remove-label="Lijn verwijderen"
          remove-position="after"
          validation="required"
          :group-repeatable-class="['tw-my-1 tw-flex tw-flex-row tw-gap-2 tw-w-full']"
        >
          <template #default="{ index }">
            <div class="md:tw-grid tw-grid-cols-8 tw-gap-x-1">
              <FormulateInput
                name="description"
                type="text"
                validation="required"
                placeholder="Verplichte lijnomschrijving"
                :label="index === 0 ? 'Omschrijving' : ''"
                outer-class="tw-my-0 tw-col-span-4"
                data-lpignore="true"
              />
              <FormulateInput
                name="general_ledger_account_id"
                type="select"
                placeholder="Grootboek"
                validation="required"
                :options="generalLedgerAccountOptions"
                :label="index === 0 ? 'Grootboek' : ''"
                outer-class="tw-my-0 tw-col-span-2"
                data-lpignore="true"
              />
              <FormulateInput
                name="vat_code_id"
                type="select"
                validation="required"
                placeholder="Btw"
                :options="vatCodeOptions"
                :label="index === 0 ? 'Btw-code' : ''"
                outer-class="tw-my-0"
                data-lpignore="true"
              />
              <FormulateInput
                name="amount"
                type="number"
                step="0.01"
                validation="required"
                placeholder="Bedrag"
                :label="index === 0 ? 'Bedrag' : ''"
                outer-class="tw-my-0"
                data-lpignore="true"
              />
            </div>
          </template>
          <template #addmore="{ addMore }">
            <button
              type="button"
              title="+ Lijn"
              class="
                tw-px-2 tw-py-0.5 tw-border tw-mt-2
                tw-text-success tw-border-success tw-rounded-md tw-shadow-card tw-bg-white
                hover:tw-bg-success hover:tw-text-white
                disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed
              "
              @click="addMore"
            >
              <i class="far fa-plus" /> Lijn
            </button>
          </template>
          <template #remove="{ index, removeItem }">
            <div class="tw-flex tw-items-end">
              <button
                type="button"
                title="Lijn verwijderen"
                class="tw-h-10 tw-text-sm tw-rounded tw-text-danger"
                @click="removeItem(index)"
              >
                <i class="fas fa-trash" />
              </button>
            </div>
          </template>
        </FormulateInput>
        <hr />
        <div v-if="totalAmount" class="tw-flex tw-flex-wrap tw-justify-end tw-mt-8 tw-my-4">
          <div class="tw-flex tw-flex-wrap tw-justify-between md:tw-w-1/4">
            <div class="tw-flex tw-gap-x-4 tw-justify-between tw-w-full">
              <span>Maatstaf van heffing</span>
              <span class="tw-text-right">{{ currency(netInvoiceAmount) }}</span>
            </div>
            <div class="tw-flex tw-gap-x-4 tw-justify-between tw-w-full">
              <span>Btw-bedrag</span>
              <span class="tw-text-right">{{ currency(vatAmount) }}</span>
            </div>
            <div class="tw-flex tw-gap-x-4 tw-justify-between tw-w-full tw-font-bold">
              <span>Totaal</span>
              <span class="tw-text-right">{{ currency(totalAmount) }}</span>
            </div>
          </div>
        </div>
        <FormulateErrors class="tw-text-right" />
        <div class="tw-flex tw-justify-end">
          <FormulateInput
            type="submit"
            :disabled="isLoading || (values.lines && !values.lines.length)"
            :input-class="['!tw-text-sm']"
          >
            <i
              :class="[
                'fas tw-mr-2',
                isLoading
                  ? 'fa-spinner-third fa-spin'
                  : 'fa-eye'
              ]"
            />
            Factuurvoorbeeld laden
          </FormulateInput>
        </div>
      </FormulateForm>
      <EntityInvoicePreview
        v-show="preview"
        ref="preview"
        @invoicing-done="invoicingDone"
        @hidePreview="hidePreview"
      />
    </BaseModal>
  </div>
</template>

<script>
import EntityInvoicePreview from '@/components/properties/EntityInvoicePreview'

import { getCompanies } from '@/services/organization'
import { getGeneralLedgerAccounts, getManualInvoicingPreview, getVatCodes } from '@/services/invoices'
import { getJournals } from '@/services/journals'
import { currency, today } from '@/utils/helpers'

export default {
  name: 'ManualInvoicingModal',
  components: {
    EntityInvoicePreview
  },
  constants: {
    today
  },
  data () {
    return {
      preview: false,
      companies: [],
      generalLedgerAccounts: [],
      journals: [],
      values: {},
      vatCodes: []
    }
  },
  computed: {
    companyOptions () {
      return this.companies.map(obj => { return { label: obj.name, value: obj.id } })
    },
    generalLedgerAccountOptions () {
      return this.generalLedgerAccounts.map(obj => { return { label: `${obj.number} - ${obj.name}`, value: obj.id } })
    },
    vatCodeOptions () {
      return this.vatCodes.map(obj => { return { label: obj.name, value: obj.id } })
    },
    netInvoiceAmount () {
      if (!this.values?.lines) return 0
      return parseFloat(this.values.lines.map(line => line.amount ? parseFloat(line.amount) : 0).reduce((a, b) => a + b, 0))
    },
    vatAmount () {
      if (!this.values?.lines) return 0
      const amountsByVatCodeId = {}
      this.values.lines.forEach(
        line => {
          if (!line.vat_code_id || !line.amount) {
            return
          }
          if (line.vat_code_id in amountsByVatCodeId) {
            amountsByVatCodeId[line.vat_code_id] += parseFloat(line.amount)
          } else {
            amountsByVatCodeId[line.vat_code_id] = parseFloat(line.amount)
          }
        }
      )
      let sum = 0
      for (const [key, value] of Object.entries(amountsByVatCodeId)) {
        const percentageForVatCode = this.vatCodes.find(obj => obj.id === parseInt(key)).percentage
        sum += (percentageForVatCode / 100) * value
      }
      return sum
    },
    totalAmount () {
      return this.netInvoiceAmount + this.vatAmount
    }
  },
  created () {
    this.init()
  },
  methods: {
    currency,
    async init () {
      const [companyResponse, generalLedgerAccountResponse, vatCodeResponse] = await Promise.all([getCompanies(), getGeneralLedgerAccounts(), getVatCodes()])
      this.companies = companyResponse?.data?.results
      this.generalLedgerAccounts = generalLedgerAccountResponse?.data?.results
      this.vatCodes = vatCodeResponse?.data?.results
      return [companyResponse, vatCodeResponse]
    },
    async handleCompanyChange () {
      const response = await getJournals({ company: this.values.company })
      this.journals = response?.data?.results
      this.$set(this.values, 'journal', null)
      return response
    },
    async showPreview (data) {
      data.invoice_recipient_grouping_id = data.invoice_recipient_grouping.id
      const response = await getManualInvoicingPreview(data)
      this.preview = true
      this.$refs.preview.showForManualInvoicing(response?.data, data)
      return response
    },
    show () {
      this.preview = false
      this.values = {}
      this.$refs.modal.show()
    },
    invoicingDone () {
      this.$emit('invoicingDone')
    },
    hidePreview () {
      this.preview = false
    }
  }
}
</script>
